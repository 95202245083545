@use 'styles/theme/theme' as theme;

.filterItem {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 14px;
  border: 1px solid theme.$tertiaryGrey;
  border-radius: 24px;
  height: 34px;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
  width: 140px;
  max-width: 140px;

  &:hover {
    background-color: theme.$whiteSmoke;
  }
}

.inputProps {
  height: 34px;
}



@media screen and (max-width: 768px) {
  .filterItem {
    min-width: 165px;
    width: auto;
    max-width: 280px;
    flex: 1;
    border-radius: 8px;
    padding-left: 0px;
  }

  .filterText {
    padding-left: 12px;
  }
}