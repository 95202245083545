@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  &__sticky {
    margin-bottom: 0;
  }
}

.root .professionDropdown {
  padding-bottom: 0;
  width: 140px;
  max-width: 140px;
  & > div {
    & > div {
      border-radius: 32px;
      height: 34px !important;
      & input {
        font-family: theme.$base-font-family;
        font-size: theme.$font-size-12;
        font-style: theme.$font-style-normal;
        line-height: theme.$font-style-normal;
      }
    }
  }
}

.filterContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  flex: 0;
}

.chipContainer {
  display: flex;
  align-items: center;
  overflow: auto;
  width: 100%;
  flex: 1;
}

.separator {
  font-size: 20px;
  color: theme.$primaryGrey;
  margin: 0 12px;
}

.scrollableContent {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-right: 16px;
}

.chipItem {
  display: flex;
  align-items: center;
  height: 28px;
  padding: 6px 10px;
  cursor: pointer;
  box-shadow: none;
  border: none;
  white-space: nowrap;
  border-radius: 8px;
  border: 1px solid theme.$borderGrey;
  background: #fff;

  &__selected {
    background: theme.$blueHover;
    .chipText {
      color: theme.$blue;
      font-weight: theme.$font-weight-bold;
    }
  }

  &:hover {
    text-decoration: underline;
    .chipText {
      color: theme.$blue;
    }
  }
}

.chipText {
  color: theme.$primaryGrey;
}

@media screen and (max-width: 1280px) {
  .root__sticky {
    flex-direction: row !important;
  }
  .chipContainer__sticky {
    overflow: unset !important;
  }
  .filterContainer__sticky {
    margin-bottom: 0 !important;
    flex-wrap: nowrap !important;
    margin-right: 8px !important;
    width: unset !important;
  }
}

@media screen and (max-width: 768px) {
  .root {
    flex-direction: column;
  }

  .chipContainer {
    max-width: 100%;
  }

  .filterContainer {
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 40px;
    justify-content: space-between;
  }

  .separator {
    display: none;
  }

  .root .professionDropdown {
    min-width: 165px;
    width: auto;
    max-width: 280px;
    flex: 1;
    & > div {
      & > div {
        border-radius: 8px;
        height: 34px !important;
      }
    }
  }
}
