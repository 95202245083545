@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  &_orgTypes {
    display: flex;
    width: 100%;
    gap: 10px;
    width: 100%;
    flex: 0;
  }
  &__sticky {
    margin-bottom: 0;
    flex-direction: row !important;
    gap: 8px !important;
  }
}

.root .dropdown {
  padding-bottom: 0;
  & > div {
    & > div {
      border-radius: 32px;
      height: 34px !important;
      & input {
        font-family: theme.$base-font-family;
        font-size: theme.$font-size-12;
        font-style: theme.$font-style-normal;
        line-height: theme.$font-style-normal;
        max-width: calc(100% - 36px) !important;
      }
    }
  }
  &_orgType,
  &_countryDropdown,
  &_cityDropdown {
    & > div {
      & > div {
        min-width: 140px;
      }
    }
  }
}

.chipContainer {
  display: flex;
  align-items: center;
  overflow: auto;
  width: 100%;
  flex: 1;
}

.separator {
  font-size: 20px;
  color: theme.$primaryGrey;
  margin: 0 12px;
}

.scrollableContent {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-right: 16px;
}

.chipItem {
  display: flex;
  align-items: center;
  height: 28px;
  padding: 6px 10px;
  cursor: pointer;
  box-shadow: none;
  white-space: nowrap;
  border-radius: 8px;
  border: 1px solid theme.$borderGrey;
  background: #fff;

  &__selected {
    background: theme.$blueHover;
    .chipText {
      color: theme.$blue;
      font-weight: theme.$font-weight-bold;
    }
  }

  &:hover {
    text-decoration: underline;
    .chipText {
      color: theme.$blue;
    }
  }
}

@media screen and (max-width: 1280px) {
  .root_orgTypesSticky {
    flex-direction: row !important;
    border: none !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    flex-wrap: nowrap !important;
  }
  .chipContainer__sticky {
    overflow: unset !important;
  }
}

@media screen and (max-width: 768px) {
  .root {
    flex-direction: column;
    &_orgTypes {
      flex-wrap: wrap;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid theme.$borderGrey;
      margin-bottom: 16px;
    }
  }

  .chipContainer {
    max-width: 100%;
  }

  .filterContainer {
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 12px;
  }

  .separator {
    display: none;
  }

  .root .dropdown {
    & > div {
      & > div {
        border-radius: 8px;
      }
    }
    &_orgType {
      width: 100%;
      border: 8px;
    }
    &_countryDropdown,
    &_cityDropdown {
      min-width: 153px;
      width: auto;
      max-width: 210px;
      flex: 1;
      border: 8px;
    }
  }
}
